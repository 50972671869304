import React, { useEffect } from 'react';
import { Ctn, ICtnProps, Typo, Bx, TBreakpointContextProps, IconBtn, GatedContent, Btn } from '@curry-group/mui-curcuma';
import { faMessageBot, faRedo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import remarkGfm from 'remark-gfm';
import { useDispatch, useSelector } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { debounce as _debounce } from 'lodash';
import { useFilterState } from '../../../data/sagas/filter';
import Markdown from 'react-markdown';
import { Skeleton } from '@material-ui/lab';
import { HeadSearchInput } from '../../../components/head-search-input';
import { changeQueryTermAction } from '../../../data/actions/filter';
import { botResponseEndAction, trackBotFeedbackAction, fetchBotAnswerRequestAction, botSetFingerprintAction } from '../../../data/actions/bot';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

interface ILandingPageBotSectionProps {
  containerMaxWidth: ICtnProps['maxWidth'];
  headline: string;
  responsive: TBreakpointContextProps;
}

const useStyles = makeStyles(theme => ({
  markdown: {
    '& > *:first-child': {
      marginTop: 0
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    }
  }
}));

export const LandingPageBotSection: React.FunctionComponent<ILandingPageBotSectionProps> = ({
    containerMaxWidth,
    headline,
    responsive
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const botWorking = useSelector(state => state.bot?.botSearch?.botWorking);
  const botError = useSelector(state => state.bot?.botSearch?.botError);
  const botAnswer = useSelector(state => state.bot?.botSearch?.botAnswer);
  const botRequestId = useSelector(state => state.bot?.botSearch?.botRequestId);
  const botFeedback = useSelector(state => state.bot?.botSearch?.botFeedback);
  const positiveFeedback = useSelector(state => state.filter?.filterItem?.content?.positiveFeedback);
  const negativeFeedback = useSelector(state => state.filter?.filterItem?.content?.negativeFeedback);
  const { searchObject } = useFilterState();

  const fingerprint = useSelector(state => state.bot?.fingerprint);

  useEffect(() => {
    // Initialize FingerprintJS and get the visitorId
    const getFingerprint = async (localBotPrint?: string) => {
      let visitorId = localBotPrint;
      if (!visitorId) {
        // Load the FingerprintJS agent asynchronously.
        const fp = await FingerprintJS.load();
        // Get the visitor identifier when it’s ready.
        const result = await fp.get();
        // This is the visitor identifier:
        visitorId = result.visitorId;
        localStorage.setItem('_mto_xb_', visitorId);
      }
      dispatch(botSetFingerprintAction({fingerprint: visitorId || ''}));
    };
    if (!fingerprint) {
      const localBotPrint = localStorage.getItem('_mto_xb_') || '';
      getFingerprint(localBotPrint);
    }
  }, [dispatch, fingerprint]);

  let feedbackItems: Array<{name:string; pos:boolean;}> = [];
  if (!!positiveFeedback && positiveFeedback.length > 0) {
    feedbackItems = [...feedbackItems, ...positiveFeedback.map((item: { name: string; }) => ({name: item.name, pos:true}))];
  }
  if (!!negativeFeedback && negativeFeedback.length > 0) {
    feedbackItems = [...feedbackItems, ...negativeFeedback.map((item: { name: string; }) => ({name: item.name, pos:false}))];
  }

  const dispatcher = {
    debouncedFetchBotAnswerRequest: _debounce((anontoken: string) => {
      dispatch(fetchBotAnswerRequestAction?.({anontoken}));
    }, 350),
    trackBotFeedback: (botRequestId: string, feedback: string, rating: number, queryTerm?: string, anonToken?: string) => {
      dispatch(trackBotFeedbackAction?.({ botRequestId, feedback, rating, queryTerm, anonToken }));
    },
    setBotResponse: (botAnswer: string, botRequestId: string) => dispatch(botResponseEndAction?.({response: botAnswer, request_id: botRequestId})),
    changeQueryTerm: (term: string) => {
      dispatch(changeQueryTermAction?.({ term }));
    }
  };

  return (
    <Ctn maxWidth={containerMaxWidth}>
      <Typo variant="h1" component="h2" dangerouslySetInnerHTML={{ __html: headline }} />
      <Bx mt={2}>
        <Typo variant="body2boosted" component="p">
          Der MTO Such-Bot ist der Startschuss neue Wege der Information und Interaktion zu gehen - schnell, einfach, zuverlässig und automatisiert.
          Fragen Sie den Bot und erhalten Sie Antworten auf Basis des Expertenwissens auf Medtec Online mitsamt Quellen.
          Probieren Sie es gerne aus.
        </Typo>
      </Bx>
      <Bx display={'flex'} mt={2}
        style={{
          border: '1px solid #000',
          borderRadius: 16,
          padding: '16px'
        }}
      >
        <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
          <HeadSearchInput
            searchIcon={null}
            input={{
              id: 'head-search',
              value: searchObject?.queryTerm,
              placeholder: 'Bot zu Fachwissen auf Medtec Online fragen',
              onChange: e => {
                const val = e.target.value;
                if (!!botAnswer && val?.length > 0) {
                  dispatcher.setBotResponse?.('', '');
                }
                dispatcher.changeQueryTerm?.(val);
              },
              onKeyPress: e => {
                if (e.key === 'Enter') {
                  dispatcher.debouncedFetchBotAnswerRequest?.(fingerprint || '');
                }
              },
              autoFocus: true
            }}
            inputLabel={{ htmlFor: 'head-search' }}
            clearOnClick={() => {
              dispatcher.changeQueryTerm?.('');
              dispatcher.setBotResponse?.('', '');
            }}
            style={{
              width:'100%'
            }}
            
          />
        </Bx>
        <Bx ml={2}>
          <IconBtn
            variant="contained" shape="circular"
            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
            size="medium"
            onClick={() => {
              dispatcher.setBotResponse?.('', '');
              dispatcher.debouncedFetchBotAnswerRequest?.(fingerprint || '');
            }}
            title='Abschicken'
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </IconBtn>
        </Bx>
      </Bx>
      {!botError &&
      <Bx display="flex"
        mt={2}
        flexDirection={isMobile ? 'column' : 'row'}
        style={{
          border: '1px solid transparent',
          borderRadius: 16,
          background:'rgb(230, 245, 251)',
          padding:'16px'
        }}
      >
        <Bx pr={2}>
          <Typo variant="h1" component="p">
            <FontAwesomeIcon icon={faMessageBot} />
          </Typo>
        </Bx>
        <Bx>
          {(!botWorking && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
            Geben Sie oben eine fachliche Frage ein und schicken Sie Ihre Anfrage per Eingabe-Taste (ENTER) oder den blauen Button ab.
            </Typo>}
          {(botWorking && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
            &nbsp;
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
            &nbsp;
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
          </Typo>}
          {!!botAnswer && <Markdown className={clsx(classes.markdown)} remarkPlugins={[remarkGfm]} linkTarget="_blank">{(botAnswer ?? '')}</Markdown>}
        </Bx>
      </Bx>}
      {botError &&
      <Bx mt={2}>
        <GatedContent
          gradientColor="divider"
          classes={{
            container: 'position-relative overflow-hidden mt-m10',
            text: 'pt-10 gated-content-gradient',
            textContainer: 'position-absolute inset-0',
            children: 'position-relative pt-10'
          }}
        >
          <div className="bgc-primary-main text-color-primary-contrastText border-radius-borderRadius lg:p-4 p-2 box-shadow-5">
            <div className="d-flex">
              <div className="mr-2 position-relative xs:d-none md:d-block" style={{ flex: '0 0 25%' }}>
                <img className="position-absolute inset-0" style={{ width: '100%', maxHeight: '100%' }} src="/images/welcome.svg" alt="Register Teaser" />
              </div>
              <div style={{ flex: '1 1' }}>
                <strong className="text-variant-h3 font-weight-normal mb-1 lg:mb-1-5 d-inline-block">
                  {'Sie möchten mehr erfahren?'}
                </strong>
                <p className="mb-0 mt-0 text-variant-body1">
                  Registrieren Sie sich kostenfrei auf Medtec Online und werden Sie Teil der neuen Fach-Community für ein erfolgreiches Zusammenspiel von
                  Medizintechnik, Innovation und Versorgung!
                </p>
                <div className="d-flex flex-direction-column sm:flex-direction-row justify-content-flex-end mt-2 lg:mt-4" style={{ gap: 8 }}>
                  <Btn
                    color="secondary"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      history.push('/optin');
                    }}
                  >
                    Jetzt registrieren
                  </Btn>
                  <Btn
                    color="default"
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      history.push('/login');
                    }}
                    style={{ backgroundColor: '#fff' }}
                  >
                    Anmelden
                  </Btn>
                </div>
              </div>
            </div>
          </div>
        </GatedContent>
      </Bx>}
      {botRequestId &&
        <Bx my={2} display={'flex'}
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
        <Bx>
          <IconBtn
            onClick={() => {
              dispatcher.setBotResponse?.('', '');
              dispatcher.debouncedFetchBotAnswerRequest?.(fingerprint || '');
            }}
            size="small"
          >
            <FontAwesomeIcon icon={faRedo} />
          </IconBtn>
        </Bx>
        {botAnswer && !!feedbackItems && feedbackItems.length > 0 && feedbackItems.map((item, index) => {
          const itemColor = item.pos
            ? theme.palette.secondary.main
            : theme.palette.error.main;
          return (
            <Bx
              key={`feedback-index-${index}`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              minWidth={36}
              px={1.5}
              py={.5}
              ml={1}
              mb={1}
              style={{
                cursor:'pointer',
                borderRadius:8,
                border:`1px solid ${botFeedback === item.name ? 'transparent' : itemColor}`,
                background: botFeedback === item.name ? itemColor : 'transparent',
                color: botFeedback === item.name ? 'white' : itemColor
              }}
              onClick={() => {
                dispatcher.trackBotFeedback?.(botRequestId, item.name, 1, searchObject?.queryTerm, fingerprint);
              }}
            >
              <Typo
                style={{
                  wordBreak:'keep-all'
                }}
                variant="body1"
              >{item.name}</Typo>
            </Bx>
        )})}
        {!!botFeedback && <Bx
          key={`BOT_FEEDBACK_ITEM_FEELGOOD_${feedbackItems.length + 1}`}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth={36}
          px={1.5}
          py={.5}
          ml={1}
          mb={1}
        >
          <Typo style={{wordBreak:'keep-all'}}variant="body1">{'Danke für Ihr Feedback!'}</Typo>
        </Bx>}
      </Bx>}
    </Ctn>
  );
};
