import React, { ReactChild, ReactNode } from 'react';
import { Bx, IIconAvaProps, ExtendedListItem, Typo, IconBtn, Indicator } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faBookmarkLight, faLock, faShare } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Badge } from '../default/badge';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';
import { GetListDateByType, IMemoryListItem, ThingTypes } from '../../../model/ryve/Thing';
import { IDictionary } from '../../../helper';
import { IListUser } from '../../../model/search/SearchResult';

export interface IParticipantListItemProps {
  title: string;
  type: string;
  typeId?: string;
  badge?: ReactNode;
  subtitle?: string;
  subtitle_2?: string;
  description?: string;
  body?: string;
  itemTemplate?: string;
  source?: string;
  color?: IIconAvaProps['color'];
  fontcolor?: string;
  highlight?: boolean;
  to?: string;
  userLink?: string;
  target?: string;
  selected?: (e:any) => void;
  shareClick?: (e:any) => void;
  hasMenu?: boolean;
  menuClicked?: (close: () => void) => ReactNode;
  itemMemoClicked?: (item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE', seed?: string) => void;
  actions?: ReactNode;
  categories?: Array<string>;
  anchor?: string;
  booked?: boolean;
  noButtons?: boolean;
  noShare?: boolean;
  isHidden?: boolean;
  avatarDict?: IDictionary<IListUser>;
}

const useStyles = makeStyles(theme => ({
  anchorTarget: {
    scrollMarginTop: '120px'
  },
  btnList: {
    '& > div': {
      '& > *': {
        maxWidth: '100%'
      }
    }
  }
}));

export const ParticipantListItem: React.FC<
  IParticipantListItemProps & {
    avatar: ReactChild;
    userAvatar?: ReactChild;
    _id?: string;
    _score?: number;
    __original?: any;
    noPadding?: boolean;
  }
> = props => {
  const classes = useStyles();
  let liAvatar = props.avatar;
  if (props.to === '/optin') {
    liAvatar = (
      <Bx>
        <Indicator
          badgeContent={<FontAwesomeIcon size={'sm'} icon={faLock} title={'Eingeschränkter Zugang'} />}
          variant="standard"
          color="error"
          overlap="circular"
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {props.avatar}
        </Indicator>
      </Bx>
    );  
  }
  const itemId = props?.__original?._id ?? '';
  const noShare = props?.noShare !== false && props?.noShare !== true ? false : props?.noShare;
  const isHidden = props?.isHidden !== false && props?.isHidden !== true ? false : props?.isHidden;
  let timestamp = GetListDateByType(props?.typeId || '', props?.__original || undefined, props.itemTemplate || '');

  return (
    <Bx position="relative" bgcolor={props.highlight ? "accent.light" : undefined} px={{ xs: 0, sm: 0, md: props.noPadding ? 0 : 2 }}>
      {!!props.anchor && (<Typo component="span" className={clsx(classes.anchorTarget)} style={{width:0, height:0}} id={props.anchor}></Typo>)}
      <ExtendedListItem to={props.to} target={props.target} onClick={props.selected} avatar={liAvatar}>
        <Bx height="100%" display="flex">
          <Bx display="flex" flexDirection="column" justifyContent="center">
            {props.itemTemplate !== 'empty' && <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span">
              {!!props.badge && (<><Badge color="secondary">neu</Badge>&nbsp;&nbsp;</>)}
              <Typo component="span" variant="subtitle2" textTransform="uppercase">
                {props.type}
              </Typo>
              {!!timestamp && props.itemTemplate !== 'share' && props.itemTemplate !== 'references' && props.itemTemplate !== 'meeting' && props?.typeId !== ThingTypes.Profile &&
                (<Typo component="span" fontSize={'body1'} style={{color:'#aaa'}} textTransform="uppercase">
                  &nbsp;|&nbsp;
                </Typo>)
              }
              {!!timestamp && props.itemTemplate !== 'share' && props.itemTemplate !== 'references' && props.itemTemplate !== 'meeting' ? `${timestamp}` : ''}
            </Typo>}
            <Typo fontWeight={500} color={props.fontcolor ?? 'white'} variant="body1" component="span">
              {props.title}
            </Typo>
            {(props.subtitle || props.subtitle_2) && (
              <Bx display="flex" mt={0.25}>
                {props.subtitle && (
                  <Bx mr={2}>
                    <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span" textTransform="uppercase">
                      {props.subtitle}
                    </Typo>
                  </Bx>
                )}
                {props.subtitle_2 && (
                  <Bx>
                    <Typo variant="subtitle2" color={props.fontcolor ?? 'textPrimary'} component="span" textTransform="uppercase">
                      {props.subtitle_2}
                    </Typo>
                  </Bx>
                )}
              </Bx>
            )}
            {(props.body) && (
              <Bx display="flex" mt={0.25}>
                <Typo variant="body1" color={props.fontcolor ?? 'textPrimary'} component="span">
                  {props.body}
                </Typo>
              </Bx>
            )}
          </Bx>
          {!props.noButtons && props.actions}
          {props.itemTemplate !== 'members' && !props.noButtons &&
            <Bx display="flex" flexDirection="row" alignItems="center" height="100%" ml="auto" justifyContent="center">
              {!noShare && !isHidden && props?.typeId !== ThingTypes.OneToOne && props?.shareClick && 
              <IconBtn
                color="primary" 
                shape="rounded"
                size="medium"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  props.shareClick && props.shareClick({...props?.__original, _id: props?.typeId !== ThingTypes.Profile ? props?._id : props?.__original?._id});
                }}
              >
                <FontAwesomeIcon icon={faShare} />
              </IconBtn>
              }
              {props.itemTemplate !== 'share' && !!props.itemMemoClicked &&
              <IconBtn
                size="medium"
                shape="rounded"
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  props?.itemMemoClicked?.(
                    {
                      elementId: (
                        props?.typeId === ThingTypes.News
                          ? props.__original?.content?.url
                          : !!itemId
                            ? itemId
                            : props._id)
                        || '',
                      externalId: props?.__original?.content?.url || '',
                      type: 'thing',
                      typeId: props.typeId || ''
                    },
                    props.booked ? 'DELETE' : 'POST'
                  );
                }}
              >
                {!props.booked && <FontAwesomeIcon icon={faBookmarkLight} />}
                {!!props.booked && <FontAwesomeIcon icon={faBookmark} />}
              </IconBtn>}
            </Bx>
          }
        </Bx>
      </ExtendedListItem>
      {props.hasMenu && props.menuClicked && (
      <Bx ml="auto" position="absolute" top="calc(50% - 24px)" right="0" pr={1}>
        {props.menuClicked(() => {})}
      </Bx>
      )}
    </Bx>
  );
};
