import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Btn, BtnList, Bx, Ctn, IBxProps, ICtnProps, Typo, TBreakpointContextProps } from '@curry-group/mui-curcuma';
import Grid from '@material-ui/core/Grid';
import { LandingPageBotSection } from '../bot-section';
import { SDAlert } from '../../../components/widget/sdalert';

interface ILandingPageKeyVisualProps {
  containerMaxWidth: ICtnProps['maxWidth'];
  sectionPy: IBxProps['px'];
  sectionPx: IBxProps['px'];
  sectionPb: IBxProps['px'];
  h1FontSize: number;
  body4FontSize: number;
  imageSrc: string;
  imageAlt: string;
  registerLabel: string;
  registerLink: string;
  loginLabel: string;
  loginLink: string;
  headline: string;
  content: string;
  responsive: TBreakpointContextProps;
}

const Button = props => {
  return <Btn {...props} />;
};

export const LandingPageKeyVisual: React.FunctionComponent<ILandingPageKeyVisualProps> = ({
  containerMaxWidth,
  sectionPy,
  sectionPx,
  sectionPb,
  h1FontSize,
  body4FontSize,
  imageSrc,
  imageAlt,
  registerLabel,
  registerLink,
  loginLabel,
  loginLink,
  headline,
  content,
  responsive
}) => {
  const [firstSectionMinHeight, setFirstSectionMinHeight] = React.useState<number>(window.innerHeight * 0.75);

  React.useEffect(() => {
    function getAndSetFirstSectionMinAndMaxHeight() {
      let minHeight;

      if (responsive.xl) {
        minHeight = window.innerHeight * 1;
      } else if (responsive.wqhdUp) {
        minHeight = window.innerHeight * 0.9;
      } else {
        minHeight = window.innerHeight * 0.75;
      }

      minHeight !== firstSectionMinHeight && setFirstSectionMinHeight(minHeight);
    }
    getAndSetFirstSectionMinAndMaxHeight();

    window.addEventListener('resize', getAndSetFirstSectionMinAndMaxHeight);

    return () => {
      window.removeEventListener('resize', getAndSetFirstSectionMinAndMaxHeight);
    };
  }, [firstSectionMinHeight, responsive]);

  return (
    <Bx
      component="section"
      width="100%"
      bgcolor="background.paper"
      py={sectionPy}
      px={sectionPx}
      pb={sectionPb}
      display="flex"
      alignItems="center"
    >
      <Ctn maxWidth={containerMaxWidth}>
        <Grid container spacing={10} style={{ margin: 0, width: '100%' }}>
          <Grid item xs={12} md={6} lg={5} xl={5} style={{ padding: 0 }}>
            <Bx height="100%" display="flex" justifyContent="center" flexDirection="column">
              <Typo variant="h1" component="h1" style={{ fontSize: h1FontSize }} gutterBottom dangerouslySetInnerHTML={{ __html: headline }} />
              <Typo variant="body1" component="p" style={{ fontSize: body4FontSize }} gutterBottom dangerouslySetInnerHTML={{ __html: content }} />
              <Bx mt={4}>
                <BtnList spacing={1}>
                  <Button color="secondary" size="large" variant="contained" component={RouterLink} to={registerLink}>
                    {registerLabel}
                  </Button>
                  <Button variant="outlined" size="large" component={RouterLink} to={loginLink}>
                    {loginLabel}
                  </Button>
                </BtnList>
              </Bx>
            </Bx>
          </Grid>
          <Grid item xs={12} md={6} lg={7} xl={7} style={{ padding: responsive.mdUp ? 0 : '40px 0 80px' }}>
            <Bx height="100%" display="flex" justifyContent="flex-end" flexDirection="row">
              <img src={imageSrc} alt={imageAlt} style={{ width: '52%', height: 'auto' }} />
            </Bx>
          </Grid>
        </Grid>
        <Grid container spacing={10} style={{ margin: 0, marginTop: 32, width: '100%' }}>
          <LandingPageBotSection
            headline="KI auf Medtec Online"
            containerMaxWidth={containerMaxWidth}
            responsive={responsive}
          />
          <Bx mt={2}>
            <SDAlert />
          </Bx>
        </Grid>
      </Ctn>
    </Bx>
  );
};
