import React from 'react';
import { Typo, Bx, IconBtn, Link } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { faLightbulbExclamationOn } from '@fortawesome/pro-duotone-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { userprofileAlertReadRequestAction } from '../../../data/actions/foundation';

interface IAlertProps {
  headline?: string;
}

export const Alert: React.FunctionComponent<IAlertProps> = () => {
  const dispatch = useDispatch();
  const alertAlreadyRead = useSelector(state => state.foundation?.profile?.alertRead);

  if (alertAlreadyRead) {
    return <></>;
  }

  return (
    <Bx mb={1}>
      <Bx display="flex"
        style={{
          border: '1px solid transparent',
          borderRadius: 16,
          background:'rgb(230, 245, 251)',
          padding:'16px'
        }}
      >
        <Bx pr={2}>
          <Typo variant="h1" component="p">
            <FontAwesomeIcon icon={faLightbulbExclamationOn} />
          </Typo>
        </Bx>
        <Bx>
          <Typo variant="body1" component="p">
            Willkommen auf Medtec Online. Informieren Sie sich in der Community
            &nbsp;
            <Link color={'primary'} to={'/coop/groups-projects/groups/howto-mto'} title={'Community Howto MTO'}>
              <><FontAwesomeIcon icon={faLink} />&nbsp;Howto MTO</>
            </Link>
            &nbsp;
            über die neuen Funktionen auf dem Portal oder erhalten Sie wertvolle Hinweise in unseren
            &nbsp;
            <Link color={'primary'} to={'/uwao-api/core/asset/System/About/MTO_FAQ.pdf'} target="_blank" title={'MTO FAQ PDF Dokument'}>
              <><FontAwesomeIcon icon={faLink} />&nbsp;FAQs</>
            </Link>.
          </Typo>
        </Bx>
        <Bx>
          <IconBtn color="default" size="small" onClick={() => {
            dispatch(userprofileAlertReadRequestAction({alertRead:true}));
          }} title={'Ausblenden'}>
            <FontAwesomeIcon icon={faTimes} />
          </IconBtn>
        </Bx>
      </Bx>
    </Bx>
  );
};
