import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation, useHistory, Redirect } from 'react-router-dom';
import { ConnectedDashboardContent, TDashboardViewType } from '../../../components/dashboard';
import { AppFrameHeader, AppFrameContent } from '../../../components/frame';
import { ConnectedDashboardHeader } from '../../../components/header/dashboard';
import { ConnectedSearchContent } from '../../../components/search-results';
import { useConfiguration } from '../../../data/sagas/foundation';
import { useFilterConfig } from '../../../data/sagas/filter';
import { DetailLoaderComponent } from '../../detail-loader';
import { ContainerSidebar } from '../../../components/communication/sidebar';
import { ConnectedFlowComponentFrame } from '../../flow';
import { useDispatch, useSelector } from 'react-redux';
import { ThingTypes } from '../../../model/ryve/Thing';
import { EmptyContent } from '../../../components/communication/empty-content';
import { Btn, Bx } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { IContainerConfig } from '../../../model/configuration';
import { setAppStateActiveConfigAction } from '../../../data/actions/foundation';
import { ConnectedHeaderComponent } from '../../../components/header';
import { fetchCommunicationsRequest, getNumUnreadMessagesRequest } from '../../../data/reducer/communication';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { Helmet } from 'react-helmet';
import { getReferrer } from '../../../helper';
import { NotFoundComponent } from '../../../components/detail/not-found';

export interface IContainerRoutingProps {
  alias: string;
}

export const ContainerRouting: React.FC<IContainerRoutingProps> = ({ alias }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const location = useLocation();
  const isMobile = useIsMobile();

  const config = useConfiguration(alias);
  const referrer = getReferrer(location.search);

  useFilterConfig(alias, false, false, referrer === '/start/search', config?.botSearchInitial);

  const showSidebar = !!(
    config?.showSidebar && 
    !location.pathname.endsWith('/meet') && 
    !location.pathname.endsWith('/flow') && 
    !location.pathname.endsWith('/dashboard') && 
    !location.pathname.endsWith('/search') && 
    location.pathname !== match.url + '/flow'
  );
  
  useEffect(() => {
    config && dispatch(setAppStateActiveConfigAction(config));
    if (config && showSidebar) {
      dispatch(fetchCommunicationsRequest({ types: config.sidebarTypes }));
      dispatch(getNumUnreadMessagesRequest({ types: config.sidebarTypes }));
    }
  }, [config, dispatch, showSidebar]);

  if (!config) return <></>;

  return (
    <React.Fragment>
      {/* Sidebar */}
      {showSidebar && !isMobile && <ContainerSidebar config={config} />}
      <Switch>
        {/* Flow */}
        <Route exact path={[match.url + '/flow', match.url + '/search/flow']}>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <ConnectedFlowComponentFrame alias={alias} />
        </Route>
        {/* Detail */}
        <Route exact path={[match.url + '/:type/:alias']}>
          <DetailLoaderComponent config={config} />
        </Route>
        {/* SubDetail */}
        <Route exact path={[match.url + '/:parentType/:parentAlias/:type/:alias']}>
          <DetailLoaderComponent config={config} />
        </Route>
        {/* ShareFlow */}
        <Route exact path={[match.url + '/:type/:alias/flow', match.url + '/:parentType/:parentAlias/:type/:alias/flow']}>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <ConnectedFlowComponentFrame alias={'share'} />
        </Route>
        {/* MeetFlow */}
        <Route exact path={[match.url + '/:type/:alias/meet']}>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <ConnectedFlowComponentFrame alias={'createmeeting'} />
        </Route>
        {/* Dashboard */}
        <Route path={[match.url]}>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <ContainerContent config={config} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export const ContainerContent: React.FC<{ config: IContainerConfig }> = ({ config }) => {
  const isMobile = useIsMobile();
  const communications = useSelector(state => state.communication.communications);
  const communicationsWorking = useSelector(state => state.communication.communicationsWorking);
  const history = useHistory();
  const match = useRouteMatch();
  const anon = useSelector(state => state.foundation.anon);

  const [dashboardView, setDashboardView] = useState<TDashboardViewType>('dashboard');
  const isMagazine = match.url.endsWith('/magazine');
  function showNagazineCreate() {
    setDashboardView('contribute');
  }

  let mayShowFlowInit = false;
  if (config?.showSidebar && config.sidebarTypes?.length && config.sidebarTypes.includes(ThingTypes.OneToOne)) {
    mayShowFlowInit = true;
  }

  let content = 'dashboard';
  if (mayShowFlowInit) {
    if (communicationsWorking) {
      content = '';
    } else {
      if (!communications || !communications.length) {
        content = 'init';
      } else {
        content = 'select';
      }
    }
  }

  return (
    <>
      <AppFrameHeader>
        {(history.location.pathname.endsWith('/dashboard') ||
          history.location.pathname.endsWith('/search') ||
          !isMobile ||
          (isMobile && !config.showSidebar) ||
          (!mayShowFlowInit && (!communications || !communications.length))) &&
          (content === 'dashboard' || content === 'select') && (
            <ConnectedDashboardHeader 
              onCreateContentClicked={isMagazine ? showNagazineCreate : undefined} 
              placeholder={config.name === 'Start' 
                ? 'Plattform durchsuchen'
                : config.name + ' durchsuchen'} 
              alias={config.alias} 
            />
          )}
        {isMobile && content === 'init' && <ConnectedHeaderComponent />}
      </AppFrameHeader>
      <AppFrameContent>
        <Switch>
          {anon && <Redirect to={'/optin'} />}
          <Route exact path={[match.url + '/dashboard']}>
            {content === 'dashboard' && <ConnectedDashboardContent alias={config.alias} view={dashboardView} setView={setDashboardView} />}
            {content === 'init' && (
              <EmptyContent
                caption="Noch nichts los. Beginnen Sie mit einem Chat"
                bottom={
                  <Bx pt={6}>
                    <Btn
                      size="large"
                      startIcon={<FontAwesomeIcon icon={faPlus} />}
                      onClick={() => {
                        history.push(match.url + '/flow');
                      }}
                      color={'secondary'}
                      variant={'contained'}
                    >
                      Chat erstellen
                    </Btn>
                  </Bx>
                }
              />
            )}
            {content === 'select' && <EmptyContent caption="Bitte wählen Sie einen Chat" />}
          </Route>
          <Route exact path={[match.url]}>
            {(!isMobile || (isMobile && !config.showSidebar) || (!mayShowFlowInit && (!communications || !communications.length))) && content === 'dashboard' && (
              <ConnectedDashboardContent alias={config.alias} view={dashboardView} setView={setDashboardView} />
            )}
            {content === 'init' && (
              <EmptyContent
                caption="Noch nichts los. Beginnen Sie mit einem Chat"
                bottom={
                  <Bx pt={6}>
                    <Btn
                      size="large"
                      startIcon={<FontAwesomeIcon icon={faPlus} />}
                      onClick={() => {
                        history.push(match.url + '/flow');
                      }}
                      color={'secondary'}
                      variant={'contained'}
                    >
                      Chat erstellen
                    </Btn>
                  </Bx>
                }
              />
            )}
            {isMobile && config.showSidebar && (content === 'select' || content === 'dashboard') && <ContainerSidebar config={config} />}
            {!isMobile && content === 'select' && <EmptyContent caption="Bitte wählen Sie einen Chat" />}
          </Route>
          <Route exact path={[match.url + '/search']}>
            <ConnectedSearchContent />
          </Route>
          <Route path="*">
            <NotFoundComponent />
          </Route>
        </Switch>
      </AppFrameContent>
    </>
  );
};
