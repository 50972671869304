import React, {  } from 'react';
import { Typo, Bx, Link } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { faFlask, faFlaskRoundPotion, faNewspaper } from '@fortawesome/pro-duotone-svg-icons';

interface ISDAlertProps {
  headline?: string;
}

export const SDAlert: React.FunctionComponent<ISDAlertProps> = () => {
  return (
    <Bx mb={1}>
      <Bx display="flex"
        style={{
          border: '1px solid transparent',
          borderRadius: 16,
          background:'rgb(230, 245, 251)',
          padding:'16px'
        }}
      >
        <Bx pr={2}>
          <Typo variant="h1" component="p">
            <FontAwesomeIcon icon={faFlaskRoundPotion} />
          </Typo>
        </Bx>
        <Bx>
          <Typo variant="body1" component="p">
            <Link color={'primary'}
              to={'/uwao-api/core/asset/System/About/MTO_SD_Uebersicht.pdf'}
              target="_blank"
              title={'MTO FAQ PDF Dokument'}
            >
              <><FontAwesomeIcon icon={faLink} />&nbsp;Hier</>
            </Link>
            &nbsp;
            finden Sie eine Übersicht zu allen laufenden und abgeschlossenen Strategischen BMBF-Dialogen,
            sowie relevante Communities mit allen Medtec Online-Reports und Medtec Online-Leitfäden.
          </Typo>
        </Bx>
      </Bx>
    </Bx>
  );
};
