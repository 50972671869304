import React, { FC, ReactChild, ReactNode, useState } from 'react';
import { Bx, IIconAvaProps, ExtendedListItem, Typo, Ava, IconAva, IconBtn, DropDownMenu, Indicator, Link, BtnList } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@material-ui/core';
import { faBan, faBoxCheck, faEllipsisH, faExternalLink, faLock } from '@fortawesome/pro-light-svg-icons';
import { Skeleton } from '@material-ui/lab';
import { INotification } from '../../../model/notifications';
import { resolveMessageContentLinks } from '../../communication/message/content';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { IMessageModel } from '../../../model/communication/Message';
import { MessageQuote } from '../../communication/message/quote';
import { getDetailRoute, getMessageRoute, hashCode, ListAvatarColors } from '../../../helper';
import { transformForList } from '../../../model/ryve/Thing';

export interface IActivityItemProps {
  title: string;
  type: string;
  typeId?: string;
  badge?: ReactNode;

  subtitle?: string;
  subtitle_2?: string;
  description?: string;
  body?: string;
  timestamp?: string;

  color?: IIconAvaProps['color'];
  fontcolor?: string;
  highlight?: boolean;

  to?: string;
  userLink?: string;
  target?: string;
  selected?: (e:any) => void;

  hasMenu?: boolean;
  menuClicked?: (close: () => void) => ReactNode;

  actions?: ReactNode;
  categories?: Array<string>;

  onLinkClick?: (link: string) => void;
}

const useStyles = makeStyles(theme => ({
  externalLink: {
    background: 'none',
    cursor: 'pointer',
    display: 'inline',
    fontFamily: theme.typography.fontFamily,
    fontSize: 'inherit',
    lineBreak: 'anywhere',
    padding: 0,
    justifyContent: 'left',
    border: 'none !important',
    borderRadius: 0,
    fontWeight: 400,
    textAlign: 'initial',
    textDecoration: 'underline',
    verticalAlign: 'baseline',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      background: 'none'
    }
  },
  anchorTarget: {
    scrollMarginTop: '120px'
  },
  btnList: {
    '& > div': {
      '& > *': {
        maxWidth: '100%'
      }
    }
  }
}));

export const ActivityListItem: React.FC<IActivityItemProps & {
    avatar: ReactChild;
    userAvatar?: ReactChild;
    _id?: string;
    _score?: number;
    __original?: INotification;
  }
> = ({
  __original,
  _id,
  _score,
  actions,
  avatar,
  badge,
  categories,
  color,
  description,
  body,
  timestamp,
  fontcolor,
  hasMenu,
  highlight,
  subtitle,
  subtitle_2,
  target,
  title,
  to,
  type,
  typeId,
  userAvatar,
  userLink,
  menuClicked,
  selected,
  onLinkClick
}) => {
  const classes = useStyles();
  const location = window.location.origin;
  const appconfig = useSelector(state => state.foundation.appconfig);
  const externalLinksAllowed = useSelector(state => state.foundation.profile?.externalLinksAllowed);
  const [anchorEl, setAnchorEl] = useState<{ target: HTMLElement } | null>(null);
  const [renderedOptions, setRenderedOptions] = useState<ReactNode>();
  const handleClick = (target: HTMLButtonElement) => {
    setAnchorEl({ target });
    setRenderedOptions(menuClicked?.(() => setAnchorEl(null)));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSharedObject = __original?.content?.notificationType === 'broadcast';
  const isSpecialObject = __original?.content?.notificationType === 'special';

  const messageContent = resolveMessageContentLinks(description || '');
  const resultArray = Array<React.ReactFragment>();
  for (let i = 0; i < messageContent.content.length; i++) {
    const linkEndRegExp = /( |,|, |\.|\. )$/gim;
    let messageContentChunk = messageContent.content[i];
    if (messageContentChunk.indexOf('<br/>') === 0) {
      messageContentChunk = messageContentChunk.replace('<br/>', '');
    }
    resultArray.push(<Typo key={i} dangerouslySetInnerHTML={{ __html: messageContentChunk }} fontWeight="normal" />);
    let punctuationAfterLink = '';
    let linkReplace = '';
    if (messageContent.links.length > i) {
      linkReplace = messageContent.links[i];
      const match = linkEndRegExp.exec(linkReplace);
      if (match && match[0] && match[0] !== ' ') {
        punctuationAfterLink = match[0];
        linkReplace = linkReplace.replace(linkEndRegExp, '');
      }
      const isExternalLink = !linkReplace.startsWith(location);
      resultArray.push(
        <Typo key={i + '-link'} fontWeight="normal">
          {isExternalLink && (
            <button
              className={clsx(classes.externalLink)}
              onClick={externalLinksAllowed ? () => window.open(linkReplace, '_blank'): () => onLinkClick?.(linkReplace)}
            >
              <FontAwesomeIcon icon={faExternalLink} />
              &nbsp;
              {linkReplace}
            </button>
          )}
          {!isExternalLink && (
            <Link to={'//' + linkReplace.replace(/(http|https):\/\//, '')} target="_blank">
              {linkReplace}
            </Link>
          )}
          {!!punctuationAfterLink && punctuationAfterLink}
        </Typo>
      );
    }
  };
  const linkedContents = <>{resultArray}</>

  if (to === '/optin') {
    avatar = userAvatar = (
      <Bx>
        <Indicator
          badgeContent={<FontAwesomeIcon size={'sm'} icon={faLock} title={'Eingeschränkter Zugang'} />}
          variant="standard"
          color="error"
          overlap="circular"
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {avatar}
        </Indicator>
      </Bx>
    );
  }
  const linkClick = selected ? undefined : to;

  let authorNotify = __original?.content?.authorNotify;
  let message: IMessageModel | null = null;
  let quoteOriginLink = '';
  let quoteOriginTitle = '';
  if ((isSharedObject || isSpecialObject) && !!__original?.content?.messagesResolved?.[0]) {
    if (isSpecialObject && !!__original?.content?.messagesResolved?.[1] && (__original?.content?.messagesResolved?.[0]?.quotes !== __original?.content?.messagesResolved?.[1]?.id || !!__original?.content?.originRef)) {
      message = __original?.content?.messagesResolved?.[1];
    } else {
      message = __original?.content?.messagesResolved?.[0];
    }
    const attachedThing = authorNotify && (__original?.content?.messagesResolved?.[0]?.quotes !== __original?.content?.messagesResolved?.[1]?.id  || !!__original?.content?.originRef)
      ? __original.content.originRefResolved
      : __original.content?.itemRefResolved?.content?.connectedEntryResolved?.[0];
    let attachedItems = !!attachedThing
      ? transformForList(
          [attachedThing].map(item => {
            return {
              _source: {...item, content: { ...item.content, title: item.content?.title || item.content?.Title }},
              _id: item._id,
              _score: 0
            };
          })
        )
      : [];
    if (attachedItems.length > 0) {
        const quoteOriginItem = attachedItems[0];
        quoteOriginTitle = quoteOriginItem.title;
        quoteOriginLink = getDetailRoute(quoteOriginItem, appconfig || {}) || '';
        if (!!quoteOriginLink && !!message) {
          const appendChar = quoteOriginLink.includes('?') ? '&' : '?';
          quoteOriginLink += `${appendChar}${getMessageRoute(message, quoteOriginItem)}`;
        }
    }
  }

  const typecheck = (typeId ?? '').toLowerCase();
  const isNews = typecheck.includes('news');

  if (!message && !isNews && isSharedObject && typeId?.toUpperCase() === 'SAMMLUNG') {
    const hash = hashCode(subtitle?.slice(0, 2) ?? '');
    const colorIndex = hash % 4;
    const colorObject = ListAvatarColors[colorIndex];
    avatar =
      <Ava size="large"
        src={undefined}
        style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}
        children={<FontAwesomeIcon icon={faBoxCheck} />} />;
  }

  return (
    <Bx position="relative" bgcolor={highlight ? 'accent.light' : undefined} px={{ xs: 0, sm: 0, md: 2 }}>
      <ExtendedListItem
        to={(isSharedObject && userLink) || (isSpecialObject && !to) ? userLink : to}
        target={target}
        onClick={selected}
        avatar={isSharedObject ? userAvatar : avatar}
      >
        <Bx height="100%" display="flex">
          <Bx display="flex" flexDirection="column" justifyContent="center">
            <Typo variant="subtitle2" color={fontcolor ?? 'textPrimary'} component="span">
              {localStorage.devModeSearch && (
                <span>
                  {_id} || {_score} ||
                  {categories && <>&nbsp;{categories?.map(cat => cat + ' || ')}</>}
                </span>
              )}
              {type}
            </Typo>
            <Typo fontWeight={500} color={fontcolor ?? 'white'} variant="body1" component="span">
              {title}
            </Typo>
            {!!subtitle && (
              <Bx display="flex" mt={0.25}>
                {subtitle && !isSharedObject && (
                  <Bx mr={2}>
                    <Typo variant="subtitle2" color={fontcolor ?? 'textPrimary'} component="span">
                      {subtitle}
                    </Typo>
                  </Bx>
                )}
              </Bx>
            )}
          </Bx>
          {actions}
          {!!badge && (
            <Bx display="flex" flexDirection="column" alignItems="flex-end" height="100%" ml="auto">
              <Bx>
                <Chip color="primary" label={badge} size="small" style={{height:'18px'}}/>
              </Bx>
            </Bx>
          )}
        </Bx>
      </ExtendedListItem>
      {(isSharedObject || isSpecialObject) && (
        <Bx>
          <Typo fontWeight={500} color={fontcolor ?? 'white'} variant="body1" component="span">
            {linkedContents}
          </Typo>
          {!!message && <MessageQuote
            message={message}
            onReferenceSelected={(type, data) => { onLinkClick?.(data) }}
            quoteOriginTitle={quoteOriginTitle}
            quoteOriginLink={quoteOriginLink}
            specialAuthor={message.createdByResolved?.content?.fullName || 'Benutzer'}
          />}
          {!message && !isNews && (
            <ExtendedListItem to={linkClick} target={target} onClick={selected} avatar={avatar}>
              <Bx height="100%" display="flex">
                <Bx display="flex" flexDirection="column" justifyContent="center">
                  <Typo variant="subtitle2" color={fontcolor ?? 'textPrimary'} component="span" textTransform="uppercase">
                    {localStorage.devModeSearch && (
                      <span>
                        {_id} || {_score} ||
                        {categories && <>&nbsp;{categories?.map(cat => cat + ' || ')}</>}
                      </span>
                    )}
                    {typeId}
                  </Typo>
                  <Typo fontWeight={500} color={fontcolor ?? 'white'} variant="body1" component="span">
                    {subtitle}
                  </Typo>
                  {subtitle_2 && (
                    <Bx>
                      <Typo variant="subtitle2" color={fontcolor ?? 'textPrimary'} component="span">
                        {subtitle_2}
                      </Typo>
                    </Bx>
                  )}
                </Bx>
              </Bx>
            </ExtendedListItem>
          )}
          {!message && isNews && (
            <ExtendedListItem
              to={linkClick}
              target={target}
              onClick={selected}
              avatar={undefined}
              title={subtitle}
            >
              <Bx height="100%" display="flex">
                <Bx display="flex" flexDirection="column" justifyContent="center" maxWidth={'100%'}>
                  <Bx pb={.5}>
                    <Typo variant="subtitle2" color={'textPrimary'} component="span">
                      <Typo component="span" variant="subtitle2" textTransform="uppercase">
                        News
                      </Typo>
                      <Typo component="span" fontSize={'body1'} style={{color:'#aaa'}} textTransform="uppercase">
                        &nbsp;|&nbsp;
                      </Typo>
                      <Typo component="span" variant="subtitle2">
                        {timestamp}
                      </Typo>
                    </Typo>
                  </Bx>
                  <Bx pb={2}>
                    <Typo variant="subtitle2" color={'textPrimary'} component="span">
                      {subtitle_2}
                    </Typo>
                  </Bx>
                  <Bx pb={1}>
                    <Typo fontWeight={500} color={'textPrimary'} variant="h3" component="h2">
                      {subtitle}
                    </Typo>
                  </Bx>
                  <Bx pb={2}>
                    <Typo color={'textPrimary'}  variant="body1" component="span">
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Typo>
                    &nbsp;&nbsp;
                    <Typo color={'blue'} style={{ textDecoration: 'underline', wordBreak: 'break-all' }} variant="subtitle2" component="span">
                      {to}
                    </Typo>
                  </Bx>
                  {body && <Bx pb={2}>
                    <Typo variant="body1" color={'textPrimary'} component="span">
                      {body}
                      &nbsp;
                    </Typo>
                  </Bx>}
                  {categories && categories.length > 0 && <Bx pb={2}>
                    <BtnList className={clsx(classes.btnList)}>
                      {categories.map((cat, index) =>
                        <Chip key={index} variant="outlined" size="medium" label={cat} style={{maxWidth:'100%'}} />
                      )}
                    </BtnList>
                  </Bx>}
                </Bx>
              </Bx>
            </ExtendedListItem>
          )}
        </Bx>
      )}
      {hasMenu && menuClicked && (
        <Bx ml="auto" position="absolute" top="calc(50% - 18px)" right="0">
          <IconBtn
            size="small"
            color="default"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleClick(e.currentTarget);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </IconBtn>
        </Bx>
      )}
      {anchorEl?.target && (
        <DropDownMenu anchorEl={anchorEl?.target} keepMounted open={!!anchorEl} onClose={handleClose}>
          <Bx bgcolor="background.paper" borderRadius="borderRadius">
            {renderedOptions}
          </Bx>
        </DropDownMenu>
      )}
    </Bx>
  );
};

export const ActivityListItemSkeleton = () => {
  return (
    <ExtendedListItem avatar={<Ava loading children={<FontAwesomeIcon icon={faBan} />} size="large" />}>
      <Typo fontWeight={500} variant="body1" component="span">
        <Skeleton animation="wave" style={{ width: '200px' }} />
      </Typo>
      <Typo variant="body2" component="span">
        <Skeleton animation="wave" style={{ width: '120px' }} />
      </Typo>
    </ExtendedListItem>
  );
};

export const ActivityListItemEmpty: FC<{ title?: string }> = ({ title }) => {
  return (
    <ActivityListItem
      type=" "
      subtitle=" "
      title={title || 'Keine Ergebnisse vorhanden.'}
      avatar={<IconAva color="primary" children={<FontAwesomeIcon icon={faBan} />} size="large" />}
    />
  );
};
