import { Ava, Bx, CheckboxControl, ExtendedListItem, IconAva, Input, InputEndAdornment, InputInfoText, InputLabelTextAdornment, InputValidationIcon, TimeAva, Typo } from '@curry-group/mui-curcuma';
import { faExternalLink, faQuestion } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { resolveTimestampDateOnly } from '../../../helper';
import { ThingTypes, transformForList } from '../../../model/ryve/Thing';
import { ISearchResultItem } from '../../../model/search/SearchResultItem';
import { MessageQuote } from '../../communication/message/quote';
import { FormInput } from '../controls/input';

interface IFormShareMessage {
  formData: any;
  headline?: string;
  subtitle?: string;
  isCommunityShare?: boolean;
  onChange: (value: any) => void;
}

export const FormShareMessage: React.FC<IFormShareMessage> = ({
  formData,
  headline,
  subtitle,
  isCommunityShare,
  onChange
}) => {
  const item = useSelector(state => state.flow.flowData?.itemData?.sharedItem);
  const searchResultMimic: ISearchResultItem = {
    _id: item._id || item.userId,
    _score: 0,
    _source: item.userId
      ? {
          type: ThingTypes.Profile,
          content: item
        }
      : item
  };

  const getListItemAvatar: () => any = () => {
    switch (listItem.avatar.type){
      case 'user':
      case 'image':
        return <Ava size="large" src={listItem.avatar.image}/>;
      case 'time':
        return <TimeAva size="large" fromTime={listItem.avatar.time?.from || ''} toTime={listItem.avatar.time?.to || ''}/>;
      case 'icon':
      default:
        return <IconAva color={listItem.color} children={<FontAwesomeIcon icon={listItem.avatar.icon || faQuestion} />} size="large" />;
    }
  }
  
  const listItem = transformForList([searchResultMimic])?.[0];
  const messageItem = !!formData?.itemData?.contextItem ? formData?.itemData?.sharedItem : undefined;

  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      <Bx my={headline || subtitle ? 8 : 0}>
        <Grid container spacing={4}>
          {isCommunityShare && <>
            <Grid item xs={12} lg={12}>
              <FormInput
                value={formData?.itemData?.title}
                label={'Titel'}
                placeholder={'Titel der Community-Nachricht eingeben'}
                onChange={val => {
                  onChange({ ...formData, itemData: { ...formData?.itemData, title: val } });
                }}
                minLength={5}
                maxLength={150}
              />
            </Grid>
            <Grid xs={12} item>
              <Bx pl={1}>
                <CheckboxControl
                  highlightLabel={true}
                  control={{
                    color: 'primary',
                    name: 'sendMail',
                    value: formData?.itemData?.sendMail,
                    onChange: e => {
                      onChange({ ...formData, itemData: { ...formData?.itemData, sendMail: e.target.checked } });
                    },
                    style: {border: '1px solid black', borderRadius:'4px', padding:'0'}
                  }}
                  style={{background:'white',padding:'15px 15px 15px 15px',borderRadius:'10px 10px 10px 10px',border:1,borderStyle:'solid',borderColor:'#D6D7D9'}}
                  checked={formData?.itemData?.sendMail}
                  label={
                    <Typo variant={'h5'} fontWeight={500}>
                      Mail an alle Teilnehmer der Plattform senden.
                    </Typo>
                  }
                />
              </Bx>
            </Grid>
          </>}
          <Grid item xs={12} lg={12}>
            <Input
              inputLabel={{
                children: 'Ihr Beitrag',
                mandatory: true,
                endAdornment: (
                  <InputLabelTextAdornment>
                    {formData?.itemData?.message?.length || 0} Zeichen
                  </InputLabelTextAdornment>
                ) }}
              input={{
                style: { paddingBottom: 5 },
                value: formData?.itemData?.message,
                placeholder: 'Erzählen Sie uns mehr...',
                multiline: true,
                rows: 3,
                onChange: val => {
                  onChange({ ...formData, itemData: { ...formData?.itemData, message: val.target.value } });
                },
                endAdornment:
                  <InputEndAdornment>
                    <InputValidationIcon type={(formData?.itemData?.message?.length || 0) >= 50 ? 'success' : 'error'} />
                  </InputEndAdornment>
              }}
              attachments={
                <>
                  {!!listItem && !messageItem && (
                    <>
                      <Bx mt={2} mb={2}>
                        <Divider />
                      </Bx>
                      {listItem.typeId !== ThingTypes.News && (
                        <ExtendedListItem
                          py={1}
                          avatar={getListItemAvatar()}
                        >
                          <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
                            <Typo variant="subtitle2" color="textPrimary" component="span" textTransform="uppercase">
                              {listItem.type}
                            </Typo>
                            <Typo fontWeight={500} color="textPrimary" variant="body1" component="span">
                              {listItem.title}
                            </Typo>
                          </Bx>
                        </ExtendedListItem>
                      )}
                      {listItem.typeId === ThingTypes.News && (
                        <ExtendedListItem
                          py={1}
                        >
                          <Bx pb={.5}>
                            <Typo variant="subtitle2" color='textPrimary' component="span">
                              {resolveTimestampDateOnly(item.content.pubDate)}
                            </Typo>
                          </Bx>
                          <Bx pb={2}>
                            <Typo variant="subtitle2" color='textSecondary' component="span">
                              {item.content.source}
                            </Typo>
                          </Bx>
                          <Bx pb={1}>
                            <Typo fontWeight={500} color='textPrimary' variant="h3" component="h2">
                              {item.content.title}
                            </Typo>
                          </Bx>
                          <Bx pb={2}>
                            <Typo color='textPrimary' variant="body1" component="span">
                              <FontAwesomeIcon icon={faExternalLink} />
                            </Typo>
                            &nbsp;&nbsp;
                            <Typo color='primary' style={{ textDecoration: 'underline' }} variant="subtitle2" component="span">
                              {item.content.url}
                            </Typo>
                          </Bx>
                        </ExtendedListItem>
                      )}
                    </>
                  )}
                  {messageItem && (
                    <>
                      <Bx mt={2} mb={2}>
                        <Divider />
                      </Bx>
                      <MessageQuote message={messageItem} />
                    </>
                  )}
                </>
              }
            />
            <Bx mt={1}>
              <InputInfoText type={(formData?.itemData?.message?.length || 0) < 50 ? 'error' : 'success'}>
                <Typo component="span" variant="body3boosted">
                  {(formData?.itemData?.message?.length || 0) < 50
                    ? `Ihr Beitrag sollte mindestens ${50} Zeichen haben.`
                    : 'Ausgezeichnet!'}
                </Typo>
              </InputInfoText>
            </Bx>
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};
