import { RadioControl, DropDownMenu, Bx, Btn } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

export const searchTypeSelectItems = [
  { caption: 'Combined', _id: 'combined' },
  { caption: 'Combined2', _id: 'combined2' },
  { caption: 'Elastic', _id: 'elastic' },
  { caption: 'Ontology', _id: 'ontology' }
];

const SearchTypeSelect: React.FC<{ props: any; searchType: string }> = ({ props, searchType }) => {
  return (
    <Bx key={'mto_news_header_search_type_select'} mr={1}>
      <Btn
        title={searchTypeSelectItems.find((item: any) => item._id === searchType)?.caption || 'Such-Typ'}
        size={props.smallVariant ? 'small' : 'medium'}
        color={props.open === 'mto_news_header_search_type_select' ? 'primary' : 'default'}
        variant={props.open === 'mto_news_header_search_type_select' ? 'contained' : 'outlined'}
        onClick={e => {
          props.filterFunctions?.click?.(e);
        }}
        aria-controls={'mto_news_header_search_type_select'}
        aria-haspopup="true"
        startIcon={<FontAwesomeIcon icon={faSearchPlus} />}
        style={{
          height: props.smallVariant ? "36px" : "48px"
        }}
      >
        {searchTypeSelectItems.find((item: any) => item._id === searchType)?.caption || 'Such-Typ'}
      </Btn>
      <DropDownMenu
        id={`filter_${'mto_news_header_search_type_select'}`}
        elevation={0}
        getContentAnchorEl={null}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={props.anchor}
        onClose={e => props.filterFunctions?.open?.('')}
        MenuListProps={{ component: 'div' }}
        open={props.open === 'mto_news_header_search_type_select'}
        keepMounted
      >
        <Bx bgcolor="primary.main" color="primary.contrastText" p={3} width={360} borderRadius="borderRadius" style={{ outline: 'none', borderTopLeftRadius: 0 }}>
          {props.open === 'mto_news_header_search_type_select' && (
            <Grid container spacing={1}>
              {(searchTypeSelectItems || []).map((searchTypeItem: any, index: number) => {
                return (
                  <Grid key={`filter_${'mto_news_header_search_type_select'}_${index}`} item xs={12} md={6}>
                    <RadioControl
                      label={searchTypeItem.caption}
                      size="small"
                      variant="outlined"
                      control={{ color: 'accent' }}
                      highlightLabel
                      onChange={e => {
                        props.filterFunctions.onSearchTypeChange?.(searchTypeItem._id);
                      }}
                      checked={searchType === searchTypeItem._id}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Bx>
      </DropDownMenu>
    </Bx>
  );
};

export const ConnectedSearchTypeSelect = (props: any) => {
  const searchType = useSelector(state => state.filter?.searchObject?.querySearchType);
  return <SearchTypeSelect props={props} searchType={searchType || ''} />;
};
